<template>
	<div class="dashboard">
		<Breadcrumbs :items="crumbs" />
    <div class="dashboard__main">
      <div class="dashboard__main--header">
        <h1 class="ml-3 mt-3">Order Details</h1>
        <router-link :to="{ name: 'dashOrders' }">
          <button class="btn"><i class="fad fa-arrow-left fa-2x"></i></button>
        </router-link>
      </div>
      <hr>
      <div class="flex flex-wrap">
        <div class="flex__col1">
          <transition name="fadeStop">
            <Loader v-if="!order" />
          </transition>
        	<div class="card mb-3" v-if="order && order.customer">
            <h2 class="mb-2">{{order.customer.entity}}</h2>
            <h4 class="mb-2">PO# {{order.order.poNumber}}</h4>
            <p class="mb-1">{{order.created.toDate() | moment("MMM Do YYYY")}}</p>
            <p v-if="order.order && order.order.details">Order Notes: {{order.order.details}}</p>
          </div>
          <div class="card mb-3" v-if="order && order.customer">
            <h4>{{order.customer.entity}}</h4>
            <p class="mb-1">Primary Contact: {{order.customer.firstName}} {{order.customer.lastName}}</p>
            <p class="mb-1">Primary Contact Phone: {{order.customer.phone}}</p>
            <p class="mb-1">Primary Contact Email: {{order.customer.email}}</p>
            <p v-if="order.customer.anythingElse">Anything else we should know? {{order.customer.anythingElse}}</p>
          </div>
        </div>
        <div class="flex__col2">
          <div class="card mb-3" v-if="order">
            <div>
              <label for="status">Order Status:</label>
              <select name="status" id="status" v-model="order.status" @change="updateOrder()">
                <option v-for="option in statuses" v-bind:value="option">
                  {{ option }}
                </option>
              </select>
            </div>
          </div>
          <div class="card" v-if="order">
            <a :href="order.order.po" target="_blank">
              <button class="btn btn__primary mt-3">
                View PO <i class="ml-1 fad fa-external-link"></i>
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
  name: 'dashOrder',
  data() {
    return {
      statuses: ['New', 'In-Review', 'Confirmed', 'In-Transit', 'Delivered', 'Complete', 'Cancelled'],
    }
  },
  components: {
    Loader,
    Breadcrumbs
  },
  created () {
    this.$store.dispatch("getOrderFromId", this.$route.params.id);
  },
  computed: {
    ...mapState(['currentUser', 'customerProfile', 'order']),
    crumbs () {
      let crumbsArray = []
      let step1 = { title: "Dashboard", to: { name: "dashHome"}}
      let step2 = { title: "My Orders", to: { name: "custOrders"}}
      let step3 = { title: "Order Details", to: false}
      crumbsArray.push(step1)
      crumbsArray.push(step2)
      crumbsArray.push(step3)
      return crumbsArray
    },
  },
  methods: {
    updateOrder() {
      let order = this.order;
      this.$store.dispatch('updateOrder', order)
    }
  },
  beforeDestroy(){
    this.$store.dispatch('clearOrder')
  }
}
</script>
